// Generated by Framer (89324c0)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["dHhNpFXvM", "uFzeJ_GlN"];

const serializationHash = "framer-k0hEE"

const variantClassNames = {dHhNpFXvM: "framer-v-1716iu0", uFzeJ_GlN: "framer-v-1kh1vll"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 0, ease: [0.12, 0.23, 0.5, 1], type: "tween"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {off: "dHhNpFXvM", on: "uFzeJ_GlN"}

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, IIXWNy_Qs: title ?? props.IIXWNy_Qs ?? "Benefits", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "dHhNpFXvM", YUV5DNsjf: link ?? props.YUV5DNsjf} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, IIXWNy_Qs, YUV5DNsjf, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "dHhNpFXvM", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-k0hEE", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={YUV5DNsjf} openInNewTab={false} smoothScroll><motion.a {...restProps} className={`${cx("framer-1716iu0", className)} framer-1p14lsk`} data-framer-name={"off"} layoutDependency={layoutDependency} layoutId={"dHhNpFXvM"} ref={ref ?? ref1} style={{"--border-bottom-width": "0px", "--border-color": "rgba(0, 0, 0, 0)", "--border-left-width": "0px", "--border-right-width": "0px", "--border-style": "solid", "--border-top-width": "0px", borderBottomLeftRadius: 9999, borderBottomRightRadius: 9999, borderTopLeftRadius: 9999, borderTopRightRadius: 9999, ...style}} variants={{uFzeJ_GlN: {"--border-bottom-width": "1px", "--border-color": "rgb(57, 25, 82)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px"}}} {...addPropertyOverrides({uFzeJ_GlN: {"data-border": true, "data-framer-name": "on"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7Um9ib3RvLXJlZ3VsYXI=", "--framer-font-family": "\"Roboto\", \"Roboto Placeholder\", sans-serif", "--framer-font-size": "19px", "--framer-line-height": "27.15px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(57, 25, 82))"}}>Benefits</motion.p></React.Fragment>} className={"framer-c5cwa8"} data-framer-name={"Benefits"} fonts={["GF;Roboto-regular"]} layoutDependency={layoutDependency} layoutId={"g_i0t8mRW"} style={{"--extracted-r6o4lv": "rgb(57, 25, 82)", "--framer-paragraph-spacing": "0px"}} text={IIXWNy_Qs} verticalAlignment={"center"} withExternalLayout/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-k0hEE [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-k0hEE .framer-1p14lsk { display: block; }", ".framer-k0hEE .framer-1716iu0 { align-content: start; align-items: start; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 17.2890625px 28.90625px 16.4375px 27.1484375px; position: relative; text-decoration: none; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-k0hEE .framer-c5cwa8 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-k0hEE .framer-1716iu0 { gap: 0px; } .framer-k0hEE .framer-1716iu0 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-k0hEE .framer-1716iu0 > :first-child { margin-left: 0px; } .framer-k0hEE .framer-1716iu0 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 61
 * @framerIntrinsicWidth 125
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"uFzeJ_GlN":{"layout":["auto","auto"]}}}
 * @framerVariables {"IIXWNy_Qs":"title","YUV5DNsjf":"link"}
 * @framerImmutableVariables true
 */
const FramerXhcHqVPyR: React.ComponentType<Props> = withCSS(Component, css, "framer-k0hEE") as typeof Component;
export default FramerXhcHqVPyR;

FramerXhcHqVPyR.displayName = "progress";

FramerXhcHqVPyR.defaultProps = {height: 61, width: 125};

addPropertyControls(FramerXhcHqVPyR, {variant: {options: ["dHhNpFXvM", "uFzeJ_GlN"], optionTitles: ["off", "on"], title: "Variant", type: ControlType.Enum}, IIXWNy_Qs: {defaultValue: "Benefits", displayTextArea: false, title: "Title", type: ControlType.String}, YUV5DNsjf: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerXhcHqVPyR, [{family: "Roboto", style: "normal", url: "https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me4GZLCzYlKw.woff2", weight: "400"}])